import "./home.scss";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";

import LogisticsForm from "../includes/parts/form/logistics";
import PrintingForm from "../includes/parts/form/printing";

// Icons
import {
    FaAngleDoubleRight,
    FaCheckCircle
} from 'react-icons/fa';

// IMPORT ASSETS
import QualityMark from "../../assets/img/static/quality-mark.png";
import CallCenter from "../../assets/img/icons/call-center.svg";
import WorkHoursGreen from "../../assets/img/icons/work-hours-green.svg";
import WorkHoursWhite from "../../assets/img/icons/work-hours-white.svg";
import Map from "../../assets/img/static/map.png";
import PinWhite from "../../assets/img/icons/pin-white.svg";
import PinGreen from "../../assets/img/icons/pin-green.svg";
import Quality from "../../assets/img/icons/quality.svg";
import Reliability from "../../assets/img/icons/reliability.svg";
import WorldMap from "../../assets/img/icons/world-map.svg";
// import Location from "../../assets/img/icons/location.svg"

import ForkLift from "../../assets/img/static/forklift.png";
import Carousel from 'react-bootstrap/Carousel';

// SERVICES
import Transport from "../../assets/img/static/bg-transport.png";
import Freight from "../../assets/img/static/freight.png";
import Print from "../../assets/img/static/printing.png";


const Home = () => {
    return (
        <div className="home">
            {/* Main Banner */}
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className="banner-logistics">
                        {/* Adroit Group Limited */}
                        <div className="intro">
                            <h1 className="tagline source-serif-bold">Adroit Group Limited</h1>
                            <p>At Adroit Logistics we cater to a range of services within the supply chain system ; End to end Customs Clearance , Freight Forwarding transport and Other logistics services both Local and international .</p>
                            <p>We also have a printing division which offers end to end printing solutions (Printing, Stationery & office Supplies)</p>
                        </div>
                        {/* Adroit Group Limited */}
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="banner-printing">
                        {/* Adroit Group Limited */}
                        <div className="intro">
                            <h1 className="tagline source-serif-bold">Adroit Group Limited</h1>
                            <p>At Adroit Logistics we cater to a range of services within the supply chain system ; End to end Customs Clearance , Freight Forwarding transport and Other logistics services both Local and international .</p>
                            <p>We also have a printing division which offers end to end printing solutions (Printing, Stationery & office Supplies)</p>
                        </div>
                        {/* Adroit Group Limited */}
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="banner-move">
                        {/* Adroit Group Limited */}
                        <div className="intro">
                            <h1 className="tagline source-serif-bold">Adroit Group Limited</h1>
                            <p>At Adroit Logistics we cater to a range of services within the supply chain system ; End to end Customs Clearance , Freight Forwarding transport and Other logistics services both Local and international .</p>
                            <p>We also have a printing division which offers end to end printing solutions (Printing, Stationery & office Supplies)</p>
                        </div>
                        {/* Adroit Group Limited */}
                    </div>
                </Carousel.Item>
            </Carousel>
            {/* Main Banner */}

            {/* Contact Ribbon */}
            <div className="ribbon">
                <div className="container">
                    <ul className="company-info">
                        <li className="call-center" data-aos="fade-up">
                            {/* Icon */}
                            <div className="icon">
                                <img src={CallCenter} alt="" />
                            </div>
                            {/* Icon */}
                            <ul>
                                <li className="source-serif-bold">Call Center</li>
                                <li>Inquries? Give Us a Call</li>
                                <li>+254 700 000000</li>
                            </ul>
                        </li>
                        <li className="working-hours" data-aos="fade-up">
                            {/* Icon */}
                            <div className="icon">
                                <img src={WorkHoursWhite} alt="" />
                            </div>
                            {/* Icon */}
                            <ul>
                                <li className="source-serif-bold">Working Hours</li>
                                <li>Inquries? Give Us a Call</li>
                                <li>+254 700 000000</li>
                            </ul>
                        </li>
                        <li className="location" data-aos="fade-up">
                            {/* Icon */}
                            <div className="icon">
                                <img src={PinWhite} alt="" />
                            </div>
                            {/* Icon */}
                            <ul>
                                <li className="source-serif-bold">Our Location</li>
                                <li>Inquries? Give Us a Call</li>
                                <li>+254 700 000000</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Contact Ribbon */}

            {/* Who we are */}
            <div className="about">
                <div className="container us">
                    <div className="mission">
                        <div data-aos="fade-up">
                            <div className="quality-service">
                                <img src={Transport} alt="" />
                                <div className="trademark">
                                    <img src={QualityMark} alt="" />
                                </div>
                            </div>
                        </div>

                        <div data-aos="fade-up">
                            <hr />
                            <h2>Our Mission</h2>
                            <p>The provision of pertinent and timely information on customs clearing and freight forwarding. With an in-depth understanding of industry practices, our vision is to be the go-to freight problem solvers.</p>
                            <p>The provision of pertinent and timely information on customs clearing and freight forwarding. With an in-depth understanding of industry practices, our vision is to be the go-to freight problem solvers.</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* Who we are */}

        </div>
    );
};

export default Home;



