import React, { useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './includes.scss';
import { Link } from "react-router-dom";
import { useDetectOutsideClick } from "./includes";


// Icons
import {
    FaRegClock,
    FaFacebookSquare,
    FaInstagramSquare,
    FaLinkedin,
    FaMapMarkerAlt
} from 'react-icons/fa';

import {
    FiShoppingCart,
    FiChevronDown,
    FiMenu
} from "react-icons/fi";

// IMPORT ASSETS
import Adroit from "../../assets/img/icons/adroit.svg";


// Calender & Datetime
//get time and day
const getDay = () => {
    let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    let date = new Date();
    let day = days[date.getDay()]; //gets day in the position of what is returned from getDay()

    return day;
};

const getCurrentTime = () => {
    let today = new Date();
    let time =
        today.getHours() + "." + today.getMinutes() + "." + today.getSeconds();

    setTimeout(getCurrentTime, 1000);
    return time;
};


const Header = () => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (
        <header>

            {/* Top Menu */}
            <div className="top-menu">
                <div className="container">
                    <ul>
                        <li><FaRegClock />&nbsp; {getDay()}</li>
                    </ul>

                    <ul>
                        <li><a href="/quote" className="link quote">Request Quote</a></li>
                    </ul>

                    <ul>
                        {/* Social Media */}
                        <li className="mr-3">Find us on Social Media</li> &nbsp;&nbsp;&nbsp;&nbsp;
                        <li><a href="" className="social-icon"><FaFacebookSquare /></a></li>
                        <li><a href="" className="social-icon mid"><FaInstagramSquare /></a></li>
                        <li><a href="" className="social-icon"><FaLinkedin /></a></li>
                        {/* Social Media */}
                    </ul>

                </div>
            </div>
            {/* Top Menu */}


            {/* Main Menu */}
            <div className="main-menu">
                <div className="container">

                    {/* Logo */}
                    <div className="logo">
                        <Link to="/">
                            <img src={Adroit} alt="" />
                        </Link>
                    </div>
                    {/* Logo */}

                    {/* Menu Links */}
                    <ul className="menu-links">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/logistics">Logistics</Link></li>
                        <li><Link to="/printing">Printing</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                    {/* Menu Links */}

                    {/* Contact Us */}
                    <Link to="/" className="track"><FaMapMarkerAlt /> &nbsp; Track my Cargo</Link>
                    {/* Contact Us */}
                </div>
            </div>
            {/* Main Menu */}

            {/* Mobile Menu */}
            <div className="mobile-menu">
                <div className="container">
                    <div className="menu">
                        <ul className="left">
                            {/* Logo */}
                            <li className="logo">
                                <img src={Adroit} alt="" />
                            </li>
                            {/* Logo */}
                        </ul>

                        <ul className="right">
                            {/* Account */}
                            <li className="menu-link">
                                <a onClick={onClick} className="account-link"><FiMenu /></a>
                            </li>
                            {/* Account */}
                        </ul>
                    </div>
                </div>

                {/* Mobile Dropdown */}
                <ul ref={dropdownRef} className={`account ${isActive ? "active" : "inactive"}`} >
                    <div className="container">
                        <li><a href="/">Home</a> </li>
                        <li><a href="/about">About</a> </li>
                        <li><a href="/logistics">Logistics</a> </li>
                        <li><a href="/printing">Printing</a> </li>
                        <li><a href="/contact">Contact</a> </li>
                        <li><a href="/">Track my Cargo</a> </li>
                    </div>
                </ul>
                {/* Mobile Dropdown */}
            </div>
            {/* Mobile Menu */}
        </header>

    );
};


export default Header;


