// import { Link } from "react-router-dom";

const Contact = () => {
    return (
      <div className="">
          404
      </div>
    );
  };
  
  export default Contact;
  