import './form.scss';
import * as yup from 'yup';
import { Formik } from "formik";
import {
    Button,
    ButtonBlock
} from "../button/button";

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    username: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
});


const NewsletterForm = () => {
    return (
        <Formik
            initialValues={{ email: '' }}
            validate={values => {
                const errors = {};
                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (

                <form noValidate onSubmit={handleSubmit} className="newsletter">
                    <label htmlFor="" className="label">Sign up for industry alerts, <br /> news and insights from Adroit</label>
                    <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                    />
                    {/* Error */}
                    {touched.email && errors.email ? (
                        <span className="error">{errors.email}</span>
                    ) : null}
                    {/* Error */}

                    <Button />
                </form>

            )}
        </Formik>
    );
}


export default NewsletterForm;