import './form.scss';
import * as yup from 'yup';
import { Formik } from "formik";
import {
    Button,
    ButtonBlock,
    ButtonBlockContact
} from "../button/button";

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    username: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
});


const ContactForm = () => {
    return (
        <div className="contact-form">
            <Formik
                initialValues={{ name: '', email: '', message: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.name) {
                        errors.name = 'Required';
                    }

                    if (!values.message) {
                        errors.message = 'Required';
                    }

                    if (!values.email) {
                        errors.email = 'Required';
                    }
                    else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (

                    <form noValidate onSubmit={handleSubmit} className="">
                        <p className="poppins-bold ">Send us a Message</p>

                        {/* Name */}
                        <div className="name">
                            <label htmlFor="name" className="">Name</label>
                            <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                            />

                            {touched.name && errors.name ? (
                                <span className="error">{errors.name}</span>
                            ) : null}
                        </div>
                        {/* Name */}

                        {/* Email */}
                        <div className="email">
                            <label htmlFor="email" className="email">Email</label>
                            <input
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                            />

                            {touched.email && errors.email ? (
                                <span className="error">{errors.email}</span>
                            ) : null}
                        </div>
                        {/* Email */}

                        {/* Message */}
                        <div className="message">
                            <label className="message">Message</label>
                            <textarea
                                name=""
                                id=""
                                rows="10"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                            </textarea>
                        </div>

                        {/* Message */}
                        <ButtonBlockContact className="contact-submit" />
                    </form>

                )}
            </Formik>
        </div >
    );
}


export default ContactForm;