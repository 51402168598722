import "./service.scss";
import { Link } from "react-router-dom";

// IMPORT ASSETS
import Truck from "../../assets/img/static/truck.png";
import TransportSVG from "../../assets/img/icons/transport.svg";
import Transport from "../../assets/img/static/transport.png";
import WorldMap from "../../assets/img/icons/world-map.svg";



import QualityMark from "../../assets/img/static/quality-mark.png";
import CallCenter from "../../assets/img/icons/call-center.svg";
import WorkHoursGreen from "../../assets/img/icons/work-hours-green.svg";
import WorkHoursWhite from "../../assets/img/icons/work-hours-white.svg";
import Map from "../../assets/img/static/map.png";
import PinWhite from "../../assets/img/icons/pin-white.svg";
import PinGreen from "../../assets/img/icons/pin-green.svg";
import Quality from "../../assets/img/icons/quality.svg";
import Reliability from "../../assets/img/icons/reliability.svg";
import Kifwa from "../../assets/img/icons/kifwa.jpg";

import ForkLift from "../../assets/img/static/forklift.png";
import Carousel from 'react-bootstrap/Carousel';

// SERVICES
import Freight from "../../assets/img/static/freight.png";
import Print from "../../assets/img/static/printing.png";
import Customs from "../../assets/img/static/customs.png";
import Courier from "../../assets/img/static/courier.jpg";



import {
    FaDotCircle,
    FaAngleDoubleRight
} from "react-icons/fa";

const Logistics = () => {
    return (
        <div className="logistics">
            {/* Main Banner */}
            <div className="banner">
                {/* Adroit Group Limited Logistics */}
                <div className="container intro">
                    <h1>Logistics Services</h1>
                </div>
                {/* Adroit Group Limited Logistics */}
            </div>
            {/* Main Banner */}

            {/* What We Offer */}
            {/* Clearance & Freight */}
            <div className="clearance">
                <div className="container">
                    <div className="export ">
                        <div className="world-map"></div>

                        <p data-aos="fade-up">Adroit Logistics provides custom clearance of import and export consignments. Our intimate understanding of the procedures allows us to correctly prepare all pre-alerts on time, log in any electronic submissions, calculate and pay taxes, duties and excises as well as handle communication between custom authorities and our clients.</p>

                        <p data-aos="fade-up">Our services encompass air, sea and land logistics booking cargo with shipping lines, airlines, rail and road carriers to ensure goods arrive at their destinations on time and in good condition.</p>

                        <p data-aos="fade-up">In all our operations, Adroit Logistics strives to maintain the highest standards of accepted business practices in our quest to provide quality and reliable services to our clients. </p>

                        <Link to="" className="link" data-aos="fade-up">Request Quote</Link>

                        {/* Truck */}
                        <div className="truck">
                            <img src={Truck} alt="" />
                        </div>
                        {/* Truck */}
                    </div>
                </div>
            </div>
            {/* Clearance & Freight */}


            {/* Logostic Services */}
            <div className="logistics-services">
                <div className="container us">
                    <ul data-aos="fade-up">
                        <li className="custom-img">
                            <img src={Customs} alt="" />
                        </li>
                    </ul>
                    <ul data-aos="fade-up">
                        <li className="source-serif-bold custom">
                            <span>01.</span>
                            <h2>Custom <br /> Clearance</h2>

                            <p>Adroit Logistics provides custom clearance of import and export consignments. Our intimate understanding of the procedures allows us to correctly prepare all pre-alerts on time, log in any electronic submissions, calculate and pay taxes, duties and excises as well as handle communication between custom authorities and our clients.</p>
                            <p>Our close coordination with customs departments, shipping and airlines as well as other allied agencies gives us a competitive edge in the provision of our service</p>
                        </li>
                    </ul>

                    <ul data-aos="fade-up">
                        <li className="source-serif-bold freight">
                            <span>02.</span>
                            <h2>Freight <br /> Forwading</h2>
                            <p>We provide freight forwarding services for sea and air cargo direct to your market, customer or distribution point. Working closely with major freight service partners, we offer you a wide range of carrier choices to principal trade lanes worldwide.</p>
                            <p>Our cargo import-export service encompasses carrier booking, inspections and verifications for custom declaration and the overall shipment coordination with constant status updates.</p>
                            <p>From planning to delivery, let Adroit logistics be your go to partner for strategic logistics planning and execution for the international movement of your shipments. We will carry out freight rate negotiations, container tracking, customs documentation and freight consolidation, among other tasks.</p>
                            <p>Our focus markets for Customs clearance & Freight forwarding are Non-governmental & International Organizations</p>
                        </li>
                    </ul>
                    <ul data-aos="fade-up">
                        <li className="freight-img">
                            <img src={Freight} alt="" />
                        </li>
                    </ul>
                </div>
            </div>
            {/* Logostic Services */}

            {/* Transport */}
            <div className="transport">
                <div className="container">
                    <div className="export">
                        {/* Icon */}
                        <div className="logistics-1" data-aos="fade-up">
                            01.
                        </div>
                        {/* Icon */}

                        <h2 data-aos="fade-up">Courier & Distribution Services</h2>

                        {/* image */}
                        <div className="trucks" data-aos="fade-up">
                            <img src={Courier} alt="" />
                        </div>
                        {/* image */}

                        <p data-aos="fade-up">Our services are tailored specifically to your needs. We select the fastest, safest and most convenient routes to ensure that your products get to where they need to be on time and in good condition. Additionally, our costs are based on CBM capacity of the load and not the tonnage of the packages. Adroit logistics focus is to simplify distributions of goods as well as becoming a reliable and efficient partner to all customers. We are aware of the importance of time, in business operation and the cost implications that come with delays in deliveries; thus, ensure we operate with no hiccups.</p>

                    </div>
                    {/* Transport */}
                    {/* What We Offer */}
                </div>
            </div>

            {/* Quality Control & Quality Assurance */}
            <div className="quality">
                <div className="container">
                    <div className="export">
                        <h2 data-aos="fade-up">Quality Control & Quality Assurance</h2>

                        <p data-aos="fade-up">We are driven by the needs of our clients and industry standards. In this regard, we observe the following quality pillars:</p>

                        <ul data-aos="fade-up">
                            <li><FaDotCircle />&nbsp;&nbsp; Adroit Logistics is a vetted and licensed Customs Agent; No</li>
                            <li><FaDotCircle />&nbsp;&nbsp; As a professional clearing agency, we will save you time and money since all our transactions are above board, on time and hence processed without hiccups;</li>
                            <li><FaDotCircle />&nbsp;&nbsp; We intimately understand the import-export process and therefore aptly placed to guard our clients from the risk of fines and penalties;</li>
                            <li><FaDotCircle />&nbsp;&nbsp; Our pride is in increasing efficiency for your business and by easing logistical challenges;</li>
                            <li><FaDotCircle />&nbsp;&nbsp; We ensure that goods reach their destinations in good order;</li>
                            <li><FaDotCircle />&nbsp;&nbsp; We are insured.</li>
                        </ul>

                        {/* Professional Affiliation */}
                        <div className="affiliations">
                            {/* image */}
                            <div className="logo">
                                <img src={Kifwa} alt="" />
                            </div>
                            {/* image */}

                            {/* Affiliations */}
                            <div className="affili">
                                <h2>Our Professional Affiliations:</h2>
                                <p>Adroit Logistics is a proud member of the Kenya International Freight and Warehousing Association (KIFWA) - the sole epresentative of all clearing, forwarding and warehousing companies in Kenya.</p>
                            </div>
                            {/* Affiliations */}
                        </div>
                        {/* Professional Affiliation */}
                    </div>
                </div>
            </div>
            {/* Quality Control & Quality Assurance */}




            {/* Who we are */}
            <div className="about">
                <div className="container us">
                    <ul data-aos="fade-up">
                        <li className="source-serif-bold">
                            Our
                            <span>Commitment</span>
                        </li>
                    </ul>
                    <ul data-aos="fade-up">
                        <li>To provide the best service for our clients, by focusing on;</li>
                    </ul>
                    <ul data-aos="fade-up">
                        {/* Icon */}
                        <li>
                            <img src={Quality} alt="" />

                        </li>
                        {/* Icon */}
                        <li>
                            <span>Quality</span>
                            <span>Years of experience in the market have given us the opportunity to perfect our offerings. We create value through delivery of quality products, effective analysis, consistent client support, on time deliveries and error free processing</span>
                        </li>
                    </ul>
                    <ul data-aos="fade-up">
                        {/* Icon */}
                        <li>
                            <img src={Reliability} alt="" />

                        </li>
                        {/* Icon */}
                        <li>
                            <span>Realiability</span>
                            <span>To date Adroit Logistics group has delivered on all our assignments to our customers satisfaction. In this we remain beyond reproach.</span>
                        </li>
                    </ul>
                    <ul data-aos="fade-up">
                        {/* Icon */}
                        <li>
                            <img src={PinGreen} alt="" />

                        </li>
                        {/* Icon */}
                        <li>
                            <span>Time</span>
                            <span>Because we understand what you need and ensure all systems are in place ahead of time, timely delivery is almost always guaranteed.</span>
                        </li>
                    </ul>
                    <ul data-aos="fade-up">
                        {/* Icon */}
                        <li>
                            <img src={WorkHoursGreen} alt="" />

                        </li>
                        {/* Icon */}
                        <li>
                            <span>Tracking</span>
                            <span>our custom tracking system will ensure that you have visibility of your packages every step of the way.</span>
                        </li>
                    </ul>

                    <ul data-aos="fade-up">
                        {/* Icon */}
                        <li>
                            <img src={WorkHoursGreen} alt="" />

                        </li>
                        {/* Icon */}
                        <li>
                            <span>Competitive Rates</span>
                            <span>Our customers and potential customers alike can be rest assured that they will get quality services at competitive rates. As a company we go the extra mile to ensure the safety of goods under our care and our customers get value for their money. Our aim is to be among the top logistics companies in Kenya.</span>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Who we are */}

            {/* Logistics & Printing */}
            <div className="logistics-printing">
                <div className="container">
                    {/* Title */}
                    <div className="title">
                        {/* title */}
                        <div className="tailored" data-aos="fade-up">
                            <p className="source-serif-bold">
                                <span>Printing & Logistics Solutions,</span>
                                <span>Tailored for you</span>
                            </p>
                        </div>
                        {/* title */}

                        {/* Intro */}
                        <div className="info" data-aos="fade-up">
                            <p>Beyond transportation, we also offer hustle free distribution services. Let us lift your load as you focus on your core business activities.</p>
                            <Link to="/logistics">Read more</Link>
                        </div>
                        {/* Intro */}

                        {/* Image */}
                        <div className="forklift" data-aos="fade-up">
                            <div>
                                <img src={ForkLift} alt="" />
                            </div>
                        </div>
                        {/* Image */}
                    </div>
                    {/* Title */}

                </div>

            </div>
            {/* Logistics & Printing */}

        </div>
    );
};

export default Logistics;
