import React from "react";
import Normalize from 'react-normalize';

// IMPORT ASSETS
import './app.scss';
import "../../assets/fonts/fonts.scss";

import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter,
    Route,
    Link,
    Switch,
    Redirect,
    useLocation
} from "react-router-dom";


// internal imports
import AOS from 'aos';
import 'aos/dist/aos.css';


import home from "../home/home";
import about from "../about/about";
import logistics from "../service/logistics";
import printing from "../service/printing";
import contact from "../contact/contact";
import quote from "../quote/quote";
import success from "../success/success";
import NotFoundPage from "../error/notfoundpage";

import Header from "../includes/header";
import Footer from "../includes/footer";


// initialization
AOS.init({
    offset: 100,
    duration: 400,
    // easing: 'ease-in-sine',
    delay: 100,
    disable: 'mobile',
    disable: 'tablet'
  });

const App = () => {
  return (
    <div>
        <Normalize />

        <BrowserRouter>
            {/* BusinessCom Header */}
            <Header />
            {/* BusinessCom Header */}

            {/* Main Content */}
                {/* Accepted Routes */}
                <Switch>
                    <Route exact path="/" component={home} />
                    <Route exact path="/about" component={about} />
                    <Route exact path="/printing" component={printing} />
                    <Route exact path="/logistics" component={logistics} />
                    <Route exact path="/contact" component={contact} />
                    <Route exact path="/quote" component={quote} />
                    <Route exact path="/success" component={success} />

                    <Route path="*" component={NotFoundPage} />
                </Switch>
                {/* Accepted Routes */}
            {/* Main Content */}

            {/* BusinessCom Footer */}
            <Footer />
            {/* BusinessCom Footer */}
        </BrowserRouter>
    </div>
  );
}


export default App;