import { Link } from "react-router-dom";
import "./service.scss";

// IMPORT ASSETS
import Printer from "../../assets/img/static/printer.png";
import Commitment from "../../assets/img/icons/commitment.svg";
import CustomerService from "../../assets/img/icons/customer-service.svg";
import Teamwork from "../../assets/img/icons/teamwork.svg";
import Rating from "../../assets/img/icons/rating.svg";

const Printing = () => {
    return (
        <div className="printing">
            {/* Main Banner */}
            <div className="banner">
                {/* Adroit Group Limited Logistics */}
                <div className="container intro">
                    <h1>Our Printing Services</h1>
                </div>
                {/* Adroit Group Limited Logistics */}

            </div>
            {/* Main Banner */}

            {/* What We Offer */}
            {/* Clearance & Freight */}
            <div className="clearance">
                <div className="container">
                    <div className="export">
                        <h2 data-aos="fade-up">Our Multi printing Capabilities</h2>

                        <p data-aos="fade-up">We are a Company that specializes in general printing of office stationery. Our printing includes Graphic Design and Artwork.We are a customer driven organisation, operating as partners to our clients.</p>
                        <p data-aos="fade-up">Our Customer service doesn’t stop with the services and products we offer. Through our innovative market - team approach we become partners in our clients development through intelligent listening. By creating sales service teams dedicated to specific markets, we are continually deepening our understanding of issues and trends that affect our clients. That knowledge allows us to ask the right questions and identify the solutions and technology that brings our clients better quality, faster turnaround and greater efficiencies.</p>

                        {/* Truck */}
                        <div className="printer">
                            <img src={Printer} alt="" />
                        </div>
                        {/* Truck */}

                        {/* Services */}
                        <div className="service poppins-bold">
                            <span data-aos="fade-up">Brochures</span>
                            <span data-aos="fade-up">Branded Mugs</span>
                            <span data-aos="fade-up">Roll Up Banners</span>
                            <span data-aos="fade-up">Delivery Notes</span>
                            <span data-aos="fade-up">Catalogues</span>
                            <span data-aos="fade-up">Branded Caps</span>
                            <span data-aos="fade-up">Business Cards</span>
                            <span data-aos="fade-up">Clear Perspex</span>
                            <span data-aos="fade-up">Complimentary Slips</span>
                            <span data-aos="fade-up">Flyers/ Brochures</span>
                            <span data-aos="fade-up">BillBoards</span>
                            <span data-aos="fade-up">Branded Carry Bag</span>
                        </div>
                        {/* Services */}
                    </div>
                </div>
            </div>
            {/* Clearance & Freight */}

            {/* Values */}
            <div className="values">
                <div className="container">
                    <div className="core">
                        <h2 data-aos="fade-up">Our Core Values</h2>
                        <p data-aos="fade-up">The 4 core values that are fundamental to our Company’s success include:- </p>

                        {/* Values */}
                        <div className="core-values">
                            {/* Commitment */}
                            <div className="core-v" data-aos="fade-up">
                                {/* icon */}
                                <div className="icon">
                                    <img src={Commitment} alt="" />
                                </div>
                                {/* icon */}

                                <h3 className="poppins-bold">Commitment to our Clients</h3>
                                <p>By ensuring our interests are aligned with your strategic and long - term goals. We take your problems, needs and search for viable solutions.</p>
                            </div>
                            {/* Commitment */}

                            {/* Customer Service */}
                            <div className="core-v" data-aos="fade-up">
                                {/* icon */}
                                <div className="icon">
                                    <img src={CustomerService} alt="" />
                                </div>
                                {/* icon */}

                                <h3 className="poppins-bold">Exceptional Customer Service</h3>
                                <p>We believe in taking the time to listen and understand your needs. We ask for clarification when needed. We believe that this approach lays the foundation for a perfect long - term relationship.</p>
                            </div>
                            {/* Customer Service */}

                            {/* Teamwork */}
                            <div className="core-v" data-aos="fade-up">
                                {/* icon */}
                                <div className="icon">
                                    <img src={Teamwork} alt="" />
                                </div>
                                {/* icon */}

                                <h3 className="poppins-bold">Teamwork</h3>
                                <p>You get to benefit from the collective wisdom of the firm. Our team is our greatest asset, and as a team, we bring you fast reliable service in the most efficient manner.</p>
                            </div>
                            {/* Teamwork */}

                            {/* quality Assurance */}
                            <div className="core-v" data-aos="fade-up">
                                {/* icon */}
                                <div className="icon">
                                    <img src={Rating} alt="" />
                                </div>
                                {/* icon */}

                                <h3 className="poppins-bold">Quality Assurance</h3>
                                <p>Value is created in many ways, through quality products, effective analysis, consistent client support, on time deliveries and error free processing.</p>
                            </div>
                            {/* quality Assurance */}

                        </div>
                        {/* Values */}

                        <div className="vision">
                            <h2 data-aos="fade-up">Our Vision</h2>
                            <p data-aos="fade-up">We strive to deliver individualized solutions to our clients printing needs, ensuring quality service delivery.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Values */}
        </div>
    );
};

export default Printing;
