import "./quote.scss";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";

import LogisticsForm from "../includes/parts/form/logistics";
import PrintingForm from "../includes/parts/form/printing";

// Icons
import {
    FaAngleDoubleRight,
    FaCheckCircle
} from 'react-icons/fa';

// IMPORT ASSETS


const Quote = () => {
    return (
        <div className="quote ">
            {/* Request Quote */}
            <div className="request-quote">
                <div className="bg-main">
                    <div className="container">
                        {/* quote */}
                        <div className="quote">
                            {/* title */}
                            <h1>Request a Quote</h1>
                            {/* title */}

                            {/* Form */}
                            <div className="form">
                                {/* Form Title */}
                                <h2>Logistics & Printing</h2>
                                {/* Form Title */}

                                <Tabs className="form-wrapper">
                                    <TabList className="nav-tab poppins-bold">
                                        <Tab className="link">Logistics</Tab>
                                        <Tab className="link">Printing</Tab>
                                    </TabList>

                                    <div className="form">
                                        <TabPanel>
                                            <LogisticsForm/>
                                        </TabPanel>
                                        <TabPanel>
                                            <PrintingForm/>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                            {/* Form */}
                        </div>
                        {/* quote */}
                    </div>
                </div>
            </div>
            {/* Request Quote */}
        </div>
    );
};

export default Quote;



