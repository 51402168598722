import 'bootstrap/dist/css/bootstrap.min.css';
import './includes.scss';
import { Link } from "react-router-dom";

// IMPORT PARTIALS
import NewsLetterForm from "./parts/form/form";

// Icons
import {
    FaRegCopyright
} from 'react-icons/fa';

// IMPORT ASSETS
import AdroitWhite from "../../assets/img/icons/adroit-white.svg";


const getYear = () => {
    return new Date().getFullYear();
};

const Footer = () => {
    return (
        <footer className="container-fluid">
            {/* Top Footer */}
            <div className="container top-footer">
                {/* logo */}
                <div className="logo">
                    <img src={AdroitWhite} alt="" />
                </div>
                {/* logo */}

                {/* Newslettter */}
                <div className="newsletter">
                    <NewsLetterForm />
                </div>
                {/* Newslettter */}
            </div>
            {/* Top Footer */}

            {/* Mid Footer */}
            <div className="container mid-footer">
                {/* About Us */}
                <ul className="menu-block about">
                    <li><h3>About Us</h3></li>
                    <li>&nbsp;</li>
                    <li className="desc">Adroit Logistics is an emerging clearing and forwarding company that provides customs clearance and freight forwarding solutions to select clientele.</li>
                    <br />
                    <li>
                        <Link to="" className="pdf-link" download>Download Profile</Link>
                    </li>
                </ul>
                {/* About Us */}

                {/* Quick Links */}
                <ul className="menu-block quick">
                    <li><h3>Quick Links</h3></li>
                    <li>&nbsp;</li>
                    <li><Link>Home</Link></li>
                    <li><Link>Offers</Link></li>
                    <li><Link>About Us</Link></li>
                    <li><Link>Our Services</Link></li>
                </ul>
                {/* Quick Links */}

                {/* Services */}
                <ul className="menu-block service">
                    <li><h3>Services</h3></li>
                    <li>&nbsp;</li>
                    <li><Link>Make Reservation</Link></li>
                    <li><Link>Call</Link></li>
                    <li><Link>Email</Link></li>
                </ul>
                {/* Services */}

                {/* Contact Us */}
                <ul className="menu-block contact">
                    <li><h3>Contact Us</h3></li>
                    <li>&nbsp;</li>
                    <li>If you have any questions or need help, feel free to contact our team.</li>
                    <li>&nbsp;</li>
                    <li><h4> Transport and Logistics</h4></li>
                    <li><span>Office:</span> <span>+254 020 440 1986</span></li>
                    <li><span>Cell:</span> <span>+254 727 116 678</span></li>
                    <li>&nbsp;</li>
                    <li><h4>Printing contact info</h4></li>
                    <li><span>Office:</span> <span>+254 20 807 9152</span></li>
                    <li><span>Cell:</span> <span>+254 721 951 914</span></li>
                </ul>
                {/* Contact Us */}
            </div>
            {/* Mid Footer */}

            {/* Bottom Footer */}
            <div className="container-fluid bottom-footer">
                {/* Logo */}
                <div className="container">
                    <span>
                        <strong>Powered By:</strong>  BusinessCom Consulting Limited
                    </span>

                    <span>
                        <FaRegCopyright /> &nbsp; {getYear()} Adroit Group Limited
                    </span>
                </div>
                {/* Logo */}
            </div>
            {/* Bottom Footer */}
        </footer>
    );
};

export default Footer;
