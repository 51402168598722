import './form.scss';
import * as yup from 'yup';
import { Formik } from "formik";
import {
    Button,
    ButtonBlock
} from "../button/button";

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    username: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
});


const PrintingForm = () => {
    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                freight_type: '',
                city_origin: '',
                city_destination: '',
                inconterms: '',
                weight: '',
                height: '',
                width: '',
                length: '',
            }}
            validate={values => {
                const errors = {};
                if (!values.name) {
                    errors.name = 'fullname required';
                }

                if (!values.email) {
                    errors.email = 'email required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }

                if (!values.freight_type) {
                    errors.freight_type = 'select freight required';
                }
                if (!values.city_origin) {
                    errors.city_origin = 'city of origin required';
                }
                if (!values.city_destination) {
                    errors.city_destination = 'city of destination required';
                }
                if (!values.inconterms) {
                    errors.inconterms = 'inconterms required';
                }
                if (!values.weight) {
                    errors.weight = 'weight required';
                } else if (
                    !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.weight)
                ) {
                    errors.length = 'invalid length';
                }

                if (!values.height) {
                    errors.height = 'height required';
                } else if (
                    !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.height)
                ) {
                    errors.length = 'Invalid required';
                }

                if (!values.width) {
                    errors.width = 'width required';
                } else if (
                    !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.length)
                ) {
                    errors.width = 'invalid width';
                }

                if (!values.length) {
                    errors.length = 'length required';
                } else if (
                    !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.length)
                ) {
                    errors.length = 'invalid length';
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (

                <form noValidate onSubmit={handleSubmit} className="">
                    <input type="text" name="name" placeholder="Full Names" type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name} />
                    <input type="text" name="phone" placeholder="Phone: +2547000000" type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name} />
                    <input type="text" name="email" placeholder="Email" type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name} />

                    {/* Shipment Data */}
                    <div className="freight">
                        <label htmlFor="">Shipment Data</label>
                        <select name="freight_type" id="" type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}>
                            <option value="">Freight Type</option>
                        </select>
                    </div>
                    {/* Shipment Data */}

                    <input type="text" name="city_origin" placeholder="City of Depature"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city_origin} />
                    <input type="text" name="city_destination" placeholder=" Delivery City" onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city_destination} />
                    <input type="text" name="inconterms" placeholder="Inconterms" className="inconterms" onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.inconterms} />
                    <input type="text" name="weight" placeholder="Weight (Kg)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.weight} />
                    <input type="number" name="height" placeholder="Height (cm)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.height} />
                    <input type="number" name="width" placeholder="Width (cm)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.width} />
                    <input type="number" name="length" placeholder="Legnth (cm)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.length} />

                    <div className="category">
                        <li>
                            <input type="checkbox" name="category" value="fragile" />Fragile
                        </li>
                        <li>
                            <input type="checkbox" name="category" value="express delivery" />Express Delivery
                        </li>
                        <li>
                            <input type="checkbox" name="category" value="insurance" />Insurance
                        </li>
                        <li>
                            <input type="checkbox" name="category" value="packaging" />Packaging
                        </li>
                    </div>

                    {/* Errors */}
                    <div className="logistics-error">
                        {touched.name && errors.name ? (
                            <span className="error">* {errors.name}</span>
                        ) : null}
                        {touched.email && errors.email ? (
                            <span className="error">* {errors.email}</span>
                        ) : null}
                        {touched.freight_type && errors.freight_type ? (
                            <span className="error">* {errors.freight_type}</span>
                        ) : null}
                        {touched.city_origin && errors.city_origin ? (
                            <span className="error">* {errors.city_origin}</span>
                        ) : null}
                        {touched.city_destination && errors.city_destination ? (
                            <span className="error">* {errors.city_destination}</span>
                        ) : null}
                        {touched.inconterms && errors.inconterms ? (
                            <span className="error">* {errors.inconterms}</span>
                        ) : null}
                        {touched.weight && errors.weight ? (
                            <span className="error">* {errors.weight}</span>
                        ) : null}
                        {touched.height && errors.height ? (
                            <span className="error">* {errors.height}</span>
                        ) : null}

                        {touched.email && errors.email ? (
                            <span className="error">* {errors.email}</span>
                        ) : null}
                        {touched.length && errors.length ? (
                            <span className="error">* {errors.length}</span>
                        ) : null}
                    </div>
                    {/* Errors */}

                    <ButtonBlock className="submit" />
                </form>

            )}
        </Formik>
    );
}


export default PrintingForm;