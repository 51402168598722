import React from "react";
import "./success.scss";
import { Link } from "react-router-dom";

// IMPORT ASSETS


const Success = () => {
    return (
        <div className="success">
            
        </div>
    );
};

export default Success;

