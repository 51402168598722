import './button.scss';

const Button = () => {
    return (
        <button type="submit">Sign up</button>
    );
}

const ButtonBlock = () => {
    return (
        <button type="submit">Request Quote</button>
    );
}

const ButtonBlockContact = () => {
    return (
        <button type="submit">Yes Please, Contact Me</button>
    );
}

export {
    Button,
    ButtonBlock,
    ButtonBlockContact,
};