import "./contact.scss";
import { Link } from "react-router-dom";

// IMPORT PARTIALS
import ContactForm from "../../components/includes/parts/form/contact";

const Contact = () => {
    return (
        <div className="contact-us">
            <div className="container">
                <div className="contact-data">
                    {/* Info */}
                    <div className="info">
                        <ul className="">
                            <li><h1>Get in Touch</h1></li>
                            <li><h4> Transport and Logistics</h4></li>
                            <li><span>Office:</span> <span className="poppins-bold">+254 020 440 1986</span></li>
                            <li><span>Cell:</span> <span className="poppins-bold">+254 727 116 678</span></li>
                            <li>&nbsp;</li>
                            <li><h4>Printing contact info</h4></li>
                            <li><span>Office:</span> <span className="poppins-bold">+254 20 807 9152</span></li>
                            <li><span>Cell:</span> <span className="poppins-bold">+254 721 951 914</span></li>
                            <li>&nbsp;</li>
                            <li><h4>Email</h4></li>
                            <li className="poppins-bold">info@adroitlogistics.co.ke </li>
                            <li>&nbsp;</li>
                            <li className="location">
                                <div>
                                    <img src="https://hatscripts.github.io/circle-flags/flags/ke.svg" alt="" />    
                                </div> 
                                <div>
                                    Signon Airport Complex<br />
                                    P.O.BOX 12621-00100 GPO <br />
                                    Nairobi.
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* Info */}

                    {/* Form */}
                    <ContactForm/>
                    {/* Form */}
                </div>
            </div>
        </div>
    );
};

export default Contact;