import React from "react";
import "./about.scss";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

// FaYoutube
// Icons
import {
    FaYoutube
} from 'react-icons/fa';


// IMPORT ASSETS
import QualityMark from "../../assets/img/static/quality-mark.png";


const Story = () => {
    return (
        <div className="about">
            {/* Our Team */}
            <div className="team">
                <div className="container">
                    <div className="core">
                        <p className="poppins-bold" data-aos="fade-up">Our Leadership</p>
                        <h2 data-aos="fade-up">Management & Staff</h2>
                        <p data-aos="fade-up">Adroit has employed a team of dedicated and people-driven professionals to serve you. Our professionals are adept in the full repertoire of customs clearing and freight logistic services, above all, we continue to be committed advisors to our customers and associates.</p>


                        {/* Values */}
                        <div className="team-members" data-aos="fade-up">
                            <Carousel variant="dark">
                                <Carousel.Item>
                                    {/* Member */}
                                    <div className="member">
                                        {/* Image */}
                                        <div className="img">
                                            <img
                                                className="d-block w-100"
                                                src="holder.js/800x400?text=First slide&bg=f5f5f5"
                                                alt="First slide"
                                            />
                                        </div>
                                        {/* Image */}

                                        {/* Info */}
                                        <div className="info">
                                            <div>
                                                <h4 className="poppins-bold">Harry Mbevi</h4>
                                                <p className="title">MANAGING PARTNER</p>
                                                <p>With over 28 years of experience in customs clearance, freight forwarding and warehousing Harry has unmatched experience in the import-export business, gathered while working at the port of Mombasa and other border entry points. Additionally good people skills, a meticulous and logical approach in handling assignments has ensured that he has built trusted relationships with colleagues and clients alike.</p>
                                                <p>He POSSES A Certificate of Competence [C O C ] from the Federation of East African Freight Forwarders Association (FEAFFA) - a non-governmental organisation, An Affiliate of International Federation of Freight Forwarders Association ( FIAFA) representing the industry and covering over 40,000 forwarding and logistics firms, also known as the "Architects of Transport"</p>
                                            </div>
                                        </div>
                                        {/* Info */}
                                    </div>
                                    {/* Member */}
                                </Carousel.Item>

                            </Carousel>
                        </div>
                        {/* Values */}
                    </div>
                </div>
            </div>
            {/* Our Team */}

            {/* Why choose Adroit */}
            <div className="why-us">
                <div className="container">
                    <div className="core">
                        <p className="poppins-bold" data-aos="fade-up">Our Leadership</p>
                        <h2 data-aos="fade-up">Why Choose us</h2>
                        <ul >
                            <li data-aos="fade-up">Adroit Logistics is a vetted and licensed Customs Agent; No</li>
                            <li data-aos="fade-up">As a professional clearing agency, we will save you time and money since all our transactions are above board, on time and hence processed without hiccups;</li>
                            <li data-aos="fade-up">We intimately understand the import-export process and therefore aptly placed to guard our clients from the risk of fines and penalties;</li>
                            <li data-aos="fade-up">Our pride is in increasing efficiency for your business and by easing logistical challenges;</li>
                            <li data-aos="fade-up">We ensure that goods reach their destinations in good order;</li>
                            <li data-aos="fade-up">We are insured.</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Why choose Adroit */}
        </div>
    );
};

export default Story;